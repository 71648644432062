@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../assets/fonts/inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Aeonik Pro";
  src: local("Aeonik Pro"), url("../assets/fonts/AeonikPro/AeonikPro-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Aeonik Pro Bold";
  src: local("Aeonik Pro Bold"), url("../assets/fonts/AeonikPro/AeonikPro-Bold.otf") format("truetype");
}
